import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { IconQuote, IconStar } from '../Icons'
import Carousel from '../sliders/Carousel'
import { gridSizes, processGatsbyImageDataMock } from '../../common/gatsbyImageData'

const Testimonials = ({ className }) => {
  const { reviews } = useStaticQuery(
    graphql`
      query {
        reviews: allStrapiGoogleReview(
          filter: { rating: { gt: 4 }, body: { regex: "/.{10,}/" } }
          limit: 9
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            id
            fullName
            body
            image {
              gatsbyImageDataMock
            }
          }
        }
      }
    `
  )
  reviews.nodes.forEach(review => {
    processGatsbyImageDataMock(review.image, gridSizes)
  })

  const map_class = i => {
    switch (i % 3) {
      case 0:
        return '-translate-x-6 rotate-6 '
      case 1:
        return '-translate-x-3 rotate-3'
      default:
        return '-translate-x-1 -rotate-6'
    }
  }
  return (
    <Carousel
      slides={{ xs: 1, md: 2, xl: 3 }}
      infiniteLoop={false}
      className={className || 'py-8 mx-12'}
      gap={16}
      scrollStep={3}
    >
      {reviews.nodes.map((k, i) => {
        const bg_transform = map_class(i)
        return (
          <div
            key={'testimonials-' + k.id}
            className={'relative xl:h-96 2xl:h-100 transition ease-out duration-700 '}
          >
            <div
              className={'transform ' + bg_transform + ' mx-4 bg-red-700 h-full w-full absolute'}
            />
            <div
              className={
                'bg-white p-4 relative justify-center testimonial-shadow h-full flex flex-col'
              }
            >
              <div className={'text-4xl mt-8 mb-4 text-center'}>{k.fullName}</div>
              <div className={'block mx-auto'}>
                <IconStar className={'text-accent'} />
              </div>
              <div className={'quote-icon text-center text-5xl'}>
                <IconQuote />
              </div>
              <div
                className={
                  'text-sm px-8 mb-4 text-center leading-6 line-clamp-6 md:line-clamp-3 xl:line-clamp-6 2xl:line-clamp-8'
                }
              >
                {k.body}
              </div>
              <div className={'flex-grow'} />
            </div>
            <div className={'absolute w-full -top-5'}>
              <div className={'mx-auto w-15 rounded-full overflow-hidden'}>
                <GatsbyImage alt={k.fullName} image={getImage(k.image.gatsbyImageData)} />
              </div>
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}

export default Testimonials
